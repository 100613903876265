<template>
  <div data-testid="cart-drawer">
    <div v-if="cartIsLoading" class="d-flex justify-content-center bc-mt-36">
      <BaseSpinner />
    </div>

    <div v-else-if="cartNotEmpty">
      <div
        v-for="item in cart.items"
        :key="item.itemUid"
        class="cart-item position-relative"
      >
        <CartItem
          :item="item.bookDetails"
          :cart-uid="cart.uid"
          :product-uid="item.itemUid"
          clickable
        />
        <hr class="divider" />
      </div>
    </div>

    <div
      v-else
      class="d-flex flex-column align-items-center bc-mt-24"
      data-testid="empty-cart"
    >
      <p class="">{{ $label('cart.emptyCart') }}</p>

      <EmptyCartIcon />
      <BaseButton
        class="bc-mt-24"
        data-testid="back-to-shop-btn"
        @click="onGoToShopClick"
      >
        {{ $label('cart.goToShop') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CartItem from '~/components/shopCart/CartItem'
import EmptyCartIcon from '~/assets/icons/empty_cart.svg'

export default {
  components: {
    CartItem,
    EmptyCartIcon,
  },
  computed: {
    ...mapGetters({
      cart: 'shopCart/cartData',
      cartIsLoading: 'shopCart/isLoading',
    }),
    cartNotEmpty() {
      return !!(this.cart.items && this.cart.items.length)
    },
  },
  methods: {
    ...mapActions({
      updateDrawerState: 'updateDrawerState',
    }),
    onGoToShopClick() {
      this.updateDrawerState(false)

      if (this.$route.fullPath !== '/shop') {
        this.$router.push('/shop')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cart-item {
  opacity: 0;
}

@for $i from 1 through 8 {
  .cart-item:nth-child(#{$i}n) {
    animation: fadeInUp 0.3s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}
</style>
