<template>
  <VeeForm
    v-slot="{ meta: formMeta }"
    data-nw="profile-address-form"
    validate-on-mount
    @submit="submit"
  >
    <div class="mb-16">
      <label for="streetName" class="form-label">
        {{ $label('account.profile.streetName') }}
      </label>
      <VeeField
        v-slot="{ meta, field, errorMessage }"
        v-model="streetName"
        name="streetName"
        rules="required"
        validate-on-input
      >
        <input
          v-bind="field"
          id="streetName"
          type="text"
          :class="[
            'form-control',
            { 'is-valid': meta.valid },
            { 'is-invalid': !meta.valid && meta.touched },
          ]"
          aria-describedby="streetName"
        />
        <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
          {{ errorMessage }}
        </div>
      </VeeField>
    </div>

    <div class="mb-16">
      <label for="houseNumber" class="form-label">
        {{ $label('account.profile.houseNumber') }}
      </label>
      <VeeField
        v-slot="{ meta, field, errorMessage }"
        v-model="houseNumber"
        name="houseNumber"
        rules="required"
        validate-on-input
      >
        <input
          v-bind="field"
          id="houseNumber"
          type="text"
          :class="[
            'form-control',
            { 'is-valid': meta.valid },
            { 'is-invalid': !meta.valid && meta.touched },
          ]"
          aria-describedby="houseNumber"
        />
        <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
          {{ errorMessage }}
        </div>
      </VeeField>
    </div>

    <div class="mb-16">
      <label for="zipCode" class="form-label">
        {{ $label('account.profile.postcode') }}
      </label>
      <VeeField
        v-slot="{ meta, field, errorMessage }"
        v-model="zipCode"
        name="zipCode"
        rules="required"
        validate-on-input
      >
        <input
          v-bind="field"
          id="zipCode"
          type="text"
          :class="[
            'form-control',
            { 'is-valid': meta.valid },
            { 'is-invalid': !meta.valid && meta.touched },
          ]"
          aria-describedby="zipCode"
        />
        <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
          {{ errorMessage }}
        </div>
      </VeeField>
    </div>
    <BaseButton
      :disabled="!formMeta.valid"
      :class="{ loading: isLoading }"
      class="bc-mt-18 w-100"
      variant="primary"
      type="submit"
      data-nw="profile-address-submit"
    >
      {{ $label('common.save') }}
    </BaseButton>
  </VeeForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ValidationMixin from '~/mixins/validation'

export default {
  mixins: [ValidationMixin],
  data() {
    return {
      isLoading: false,
      streetName: '',
      houseNumber: '',
      zipCode: '',
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser',
    }),
  },
  created() {
    this.streetName = this.loggedInUser.streetName
    this.houseNumber = this.loggedInUser.houseNumber
    this.zipCode = this.loggedInUser.zipCode
  },
  methods: {
    ...mapActions({
      updateProfile: 'account/updateProfile',
      updateDrawerState: 'updateDrawerState',
    }),
    async submit() {
      try {
        this.isLoading = true
        await this.updateProfile({
          streetName: this.streetName.trim(),
          zipCode: this.zipCode.trim(),
          houseNumber: this.houseNumber.trim(),
        })

        this.$toast.success(
          this.$label('messages.updateProfile.successMessage'),
        )

        this.updateDrawerState({
          isDrawerShown: false,
        })
      } catch (error) {
        this.$toast.error(
          this.$toast.error(this.$label('messages.errorOccuredMessage')),
        )
        this.$sentryCaptureException(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
