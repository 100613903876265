<template>
  <BaseButton
    data-testid="close-drawer"
    variant="naked"
    class="reset-btn-styling position-relative d-flex justify-content-around align-items-center flex-column"
    @click="onClick"
  >
    <span class="line"></span>
    <span class="line"></span>
  </BaseButton>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes rotateRight {
  0% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes rotateLeft {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(-45deg);
  }
}
.line {
  background-color: #000;
  display: block;
  width: 20px;
  height: 2px;
}

button {
  width: 24px;
  height: 24px;

  :nth-child(1) {
    animation: rotateRight 0.3s forwards ease-out;
  }

  :nth-child(2) {
    animation: rotateLeft 0.3s forwards ease-out;
    position: absolute;
  }
}
</style>
