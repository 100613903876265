<template>
  <div v-if="cart.items && cart.items.length" class="d-flex flex-column">
    <div class="w-100 d-flex bc-mt-6 justify-content-between mb-24">
      <span class="total-price">{{ $label('cart.totalPrice') }}</span>
      <span class="price">{{ totalPrice }}</span>
    </div>
    <BaseButton
      variant="call-to-action"
      class="w-100"
      :disabled="removingItem"
      @click="onClick"
    >
      {{ $label('cart.pay') }}
    </BaseButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import currencyFormatter from '~/utils/currencyFormatter'

export default {
  computed: {
    ...mapGetters({
      cart: 'shopCart/cartData',
      removingItem: 'shopCart/removingItem',
    }),
    totalPrice() {
      return currencyFormatter(this.cart.totalPrice)
    },
  },
  methods: {
    ...mapActions({
      updateDrawerState: 'updateDrawerState',
    }),
    onClick() {
      this.updateDrawerState(false)
      this.$router.push('/shop/betalen')
    },
  },
}
</script>

<style lang="scss" scoped>
.total-price {
  font-size: 16px;
  font-family: $font-family-title;
}
</style>
