<script setup>
import AlertContainer from '~/components/alerts/AlertContainer'
import CookieBar from '~/components/cookieConsent/CookieBar'
import BaseDrawer from '~/components/BaseDrawer'
import ResumeSubscriptionBanner from '~/components/membership/ResumeSubscriptionBanner'
import FailedAutorenewalAlert from '~/components/alerts/FailedAutorenewalAlert'

const route = useRoute()
const isPurchaseSuccessPage = computed(() =>
  ['/gelukt', '/promo/gelukt', '/campagne/gelukt', '/aanbod/gelukt'].includes(
    route?.path,
  ),
)
</script>

<template>
  <div class="layout layout--default white-header">
    <ResumeSubscriptionBanner v-if="!isPurchaseSuccessPage" />
    <FailedAutorenewalAlert v-if="!isPurchaseSuccessPage" />

    <ClientOnly>
      <BaseDrawer />
    </ClientOnly>

    <BaseHeader is-default color="#14181a" sticky-mobile-header />

    <main>
      <slot />
    </main>

    <BaseFooter is-default />

    <AlertContainer />

    <CookieBar />
  </div>
</template>

<style lang="scss" scoped>
.layout--default {
  min-height: 100vh;
}
</style>
